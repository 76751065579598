<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center sticky-header">
            <h2 class="mb-0">
              Lead #{{ $route.params.id }}
              <CBadge :color="getBadgeColor">{{ getBadgeText }}</CBadge>
            </h2>
            <div>
              <CButton color="danger" v-if="lead.optInRequest && !lead.optInRequest.cancelled" @click="manualOptOut">
                Von E-Mails abmelden (Opt-Out)
              </CButton>
              <CButton color="primary" @click="toggleEditMode">
                <CIcon :name="editMode ? 'cil-x' : 'cil-pencil'" /> {{ editMode ? 'Abbrechen' : 'Bearbeiten' }}
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CSpinner v-if="!ready" color="primary" style="display: block; margin: 20px auto;" />
            <template v-else>
              <div v-if="!editMode" class="lead-info">
                <CRow class="equal-height-row">
                  <CCol lg="6">
                    <CCard class="h-100">
                      <CCardHeader><h3>Persönliche Informationen</h3></CCardHeader>
                      <CCardBody class="info-list">
                        <p><strong>Name:</strong> {{ lead?.holder?.fullName || 'Nicht angegeben' }}</p>
                        <p><strong>E-Mail:</strong> {{ lead?.holder?.email || 'Nicht angegeben' }}</p>
                        <p><strong>Telefon:</strong> {{ lead?.holder?.phoneNumber || 'Nicht angegeben' }}</p>
                      </CCardBody>
                    </CCard>
                  </CCol>
                  <CCol lg="6">
                    <CCard class="h-100">
                      <CCardHeader><h3>Anschrift</h3></CCardHeader>
                      <CCardBody>
                        <p>{{ lead?.organization?.contactAddress || 'Nicht angegeben' }}</p>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
                <CRow class="equal-height-row mt-4" v-if="!isObjectInfoEmpty">
                  <CCol lg="6">
                    <CCard class="h-100">
                      <CCardHeader><h3>Objektinformationen <CIcon name="cil-sort" @click="setSort('values.constructionYear')" /></h3></CCardHeader>
                      <CCardBody class="info-list">
                        <p><strong>Objekttyp:</strong> {{ translateObjectType(lead?.type) }}</p>
                        <p><strong>Baujahr:</strong> {{ lead?.values?.constructionYear || 'Nicht angegeben' }}</p>
                        <p><strong>Zimmer:</strong> {{ lead?.values?.rooms || 'Nicht angegeben' }}</p>
                        <p><strong>Wohnfläche:</strong> {{ lead?.values?.livingArea ? `${lead?.values?.livingArea} m²` : 'Nicht angegeben' }}</p>
                        <p><strong>Grundstücksfläche:</strong> {{ lead?.values?.siteArea ? `${lead?.values?.siteArea} m²` : 'Nicht angegeben' }}</p>
                        <p><strong>Etage:</strong> {{ lead?.values?.floor || 'Nicht angegeben' }}</p>
                        <p><strong>Grund:</strong> {{ lead?.values?.reason || 'Nicht angegeben' }}</p>
                        <p><strong>Zeitrahmen:</strong> {{ lead?.values?.reasonWhen || 'Nicht angegeben' }}</p>
                      </CCardBody>
                    </CCard>
                  </CCol>
                  <CCol lg="6" v-if="!isValuationEmpty">
                    <CCard class="h-100">
                      <CCardHeader><h3>Wertermittlung <CIcon name="cil-sort" @click="setSort('results.resultAbsolute')" /></h3></CCardHeader>
                      <CCardBody class="info-list">
                        <p><strong>Geschätzter Wert:</strong> {{ formatCurrency(lead?.results?.resultAbsolute) }}</p>
                        <p><strong>Untere Grenze:</strong> {{ formatCurrency(lead?.results?.lowAbsolute) }}</p>
                        <p><strong>Obere Grenze:</strong> {{ formatCurrency(lead?.results?.highAbsolute) }}</p>
                        <p><strong>Preis pro m²:</strong> {{ formatCurrency(lead?.results?.resultPerSqm) }}</p>
                        <p><strong>Untere Grenze pro m²:</strong> {{ formatCurrency(lead?.results?.lowPerSqm) }}</p>
                        <p><strong>Obere Grenze pro m²:</strong> {{ formatCurrency(lead?.results?.highPerSqm) }}</p>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </div>
              <LeadForm
                v-else
                :passed-data="lead"
                btn-text="Speichern"
                @submit="saveLead"
              >

              </LeadForm>
            </template>
          </CCardBody>
          <CCardFooter v-if="editMode" class="d-flex justify-content-between">
            <CButton color="secondary" @click="toggleEditMode">Abbrechen</CButton>
            <CButton
              color="primary"
              type="submit"
              @click="submit"
            >
              <CIcon name="cil-save" /> Speichern
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import LeadForm from './LeadForm'
import axios from "axios"
import moment from 'moment'
import { CIcon } from '@coreui/icons-vue'

export default {
  name: "EditLead",
  components: { LeadForm, CIcon },
  data() {
    return {
      lead: null,
      ready: false,
      editMode: false,
      sortKey: 'id',
      sortOrder: 'asc',
      currentPage: 1,
      itemsPerPage: 10,
      organizationWide: false,
      objectTypes: [
        { value: 'APARTMENT', label: 'Wohnung' },
        { value: 'HOUSE', label: 'Haus' },
        { value: 'LAND', label: 'Grundstück' },
        { value: 'APARTMENT_HOUSE', label: 'Mehrfamilienhaus' },
        { value: 'RENT_APARTMENT', label: 'Mietwohnung' },
        { value: 'RENT_HOUSE', label: 'Miethaus' },
      ]
    }
  },
  computed: {
    getBadgeColor() {
      return 'primary'
    },
    getBadgeText() {
      return 'Lead'
    },
    isObjectInfoEmpty() {
      return !this.lead?.values?.constructionYear &&
             !this.lead?.values?.rooms &&
             !this.lead?.values?.livingArea &&
             !this.lead?.values?.siteArea &&
             !this.lead?.values?.floor &&
             !this.lead?.values?.reason &&
             !this.lead?.values?.reasonWhen;
    },
    isValuationEmpty() {
      return !this.lead?.results?.resultAbsolute &&
             !this.lead?.results?.lowAbsolute &&
             !this.lead?.results?.highAbsolute &&
             !this.lead?.results?.resultPerSqm &&
             !this.lead?.results?.lowPerSqm &&
             !this.lead?.results?.highPerSqm;
    }
  },
  created() {
    this.fetchLead()
  },
  methods: {
    async fetchLead() {
      try {
        const response = await axios.get('/lead/' + this.$route.params.id, {
          params: {
            page: this.currentPage,
            size: this.itemsPerPage,
            organizationWide: this.organizationWide,
            sort: `${this.sortKey},${this.sortOrder}`
          }
        })
        this.lead = response.data
        this.ready = true
      } catch (err) {
        console.error(err)
        this.$toaster.makeToast('Fehler', 'Der Lead konnte nicht geladen werden.', 'danger')
      }
    },
    toggleEditMode() {
      this.editMode = !this.editMode
    },
    saveLead(lead) {
      axios.put('/lead', lead)
        .then(response => {
          this.$toaster.makeToast('Gespeichert!', 'Der Lead wurde erfolgreich gespeichert.')
          this.$router.push({name: 'Leads'})
        })
        .catch(err => {
          console.error(err)
          this.$toaster.makeToast('Fehler', 'Der Lead konnte nicht gespeichert werden.', 'danger')
        })
    },
    submit() {
      const formData = this.lead;
      this.saveLead(formData);
    },
    manualOptOut() {
      this.$modal.showModal(
          'default',
          'Manuellen Opt-Out durchführen?',
          [
              `Möchten Sie einen manuellen Opt-Out für den Kunden <b>${this.lead.holder.firstName} ${this.lead.holder.lastName}</b> ausführen?`,
              `<i>Die Kund:innen erhalten somit keine E-Mails mehr von der Marketing-Cloud.</i>`
          ],
          async () => {
            try {
              let resp = await axios.get(`/opt-in/cancel/${this.lead.optInRequest.id}`)
              this.$toaster.makeToast('Erfolgreich!', 'Der Opt-Out wurde erfolgreich durchgeführt.')
              this.lead.optInRequest.cancelled = moment().format()
            } catch (e) {
              this.$toaster.makeToast('Fehler!', 'Der Opt-Out konnte nicht durchgeführt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.')
              console.error(e)
            }
          }
      )
    },
    translateObjectType(type) {
      const foundType = this.objectTypes.find(t => t.value === type)
      return foundType ? foundType.label : 'Unbekannt'
    },
    formatCurrency(value) {
      return value ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value) : 'Nicht verfügbar'
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
    setSort(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }
      this.fetchLead();
    }
  }
}
</script>

<style scoped>
.lead-info {
  margin-bottom: 20px;
}

.card {
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.card-body {
  padding: 20px;
}

.card-body p {
  margin-bottom: 10px;
}

.card-body p strong {
  margin-right: 5px;
}

.btn {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .card {
    margin-bottom: 15px;
  }
}
.equal-height-row {
  display: flex;
  flex-wrap: wrap;
}

.equal-height-row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.equal-height-row .card {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.equal-height-row .card-body {
  flex: 1 0 auto;
}
.info-list p {
  display: flex;
  align-items: flex-start;
}

.info-list p strong {
  min-width: 150px; /* Passen Sie diesen Wert an, um die gewünschte Breite für die Labels zu erhalten */
  margin-right: 10px;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
  padding: 15px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sticky-header .btn {
  margin-left: 8px;
}
</style>
